@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~swiper/swiper-bundle";

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../../assets/fonts/Roboto-Light.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("../../assets/fonts/Roboto-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../assets/fonts/Roboto-Bold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("../../assets/fonts/Roboto-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../../assets/fonts/Roboto-Medium.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("../../assets/fonts/Roboto-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../assets/fonts/roboto-condensed-v19-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("../../assets/fonts/roboto-condensed-v19-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Roboto Condensed bold";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../assets/fonts/roboto-condensed-v19-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url("../../assets/fonts/roboto-condensed-v19-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.gradient-bg {
  background-color: #43552d;
  background-image: linear-gradient(100deg, rgb(69, 83, 49) 50%, rgb(119, 149, 77) 100%);
}

.gradient-bg-red {
  background-color: #cd1708;
  background-image: linear-gradient(100deg, rgb(169, 23, 11) 50%, rgb(255, 63, 47) 100%);
}

.header-bg {
  // background-image: asset-url("header-image.png");
  background-image: url("../../assets/images/header-img.png");
}

.green-pantone {
  color: #43552d;
}

.light-grey-bg {
  background-color: #f1f1f1;
  border-left: 8px solid #43552d;

  li:hover {
    background-color: #d2d2d2;
  }

  li {
    .active {
      background-color: #43552d;
      color: white;
    }
  }
}

.ellipse {
  border: 4px solid #43552d;
  border-radius: 50%;
}

.fix-whitespace-bottom {
  margin-bottom: 10rem;
}

.w-128 {
  width: 31rem;
}

.roaster-icon {
  path {
    fill: #fff;
  }

  svg {
    margin: 2px;
    padding-left: 5px;
  }
}

.qr-page {
  display: block;

  .list-data {
    display: inline-block;
  }

  .qr-code {
    display: inline-block;
  }
}

.fontsize-custom {
  font-size: 18px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.word-spacing {
  word-spacing: 2px;
}

.text-italic {
  font-style: italic;
}

.fa {
  span {
    font-family: Roboto Condensed;
  }
}

input[type="submit"] {
  cursor: pointer;
}

input[type="file"] {
  cursor: pointer;
}

.image-incoming-orders {
  img {
    height: 150px;
    width: 100%;
    object-fit: contain;
  }
}

.bg-e6 {
  background-color: #e6e6e6;
}

.width-30 {
  width: 31%;
}

.task-card {
  position: relative;
}

.task-card:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 50%;
  height: 30px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .6) 25%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, .6) 75%, rgba(255, 255, 255, 0) 100%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.light-bg {
  background-color: #E5E7EB;
  height: 100%;
  width: 100%;
}

.toast-bg {
  background-color: #E5E7EB;
}

.swiper-slide {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  max-width: 337px;

  img {
    height: 250px;
    width: 100%;
    object-fit: contain;
  }
}

.swiper-container {
  max-width: 337px;
  overflow: hidden;
  height: 280px;
}

@media (max-width: 767px) {
  .left-panel {
    display: none;
  }
}

@media (min-width: 768px) {
  .main-layout {
    margin-left: 250px;
  }
}

.anchor_class {
  span {
    font-family: Roboto Condensed bold;
  }
}

@import "app/assets/stylesheets/application/activity_feeds";
@import "app/assets/stylesheets/application/batches";
@import "app/assets/stylesheets/application/blenders";
@import "app/assets/stylesheets/application/business";
@import "app/assets/stylesheets/application/farmers";
@import "app/assets/stylesheets/application/packers";
@import "app/assets/stylesheets/application/roasters";
@import "app/assets/stylesheets/application/shippers";
@import "app/assets/stylesheets/application/users";
@import "app/assets/stylesheets/application/variants";

html {
  overflow: hidden;
  height: 100%;

  body {
    font-family: Roboto light;
    color: #000;
    overflow: auto;
    height: 100%;
  }
}

h1 {
  font-family: Roboto Condensed bold;
  color: #829277;
}

strong {
  font-family: Roboto Bold;
}

p {
  font-family: Roboto light;
  font-size: 1em;
}

a {
  font-family: Roboto Light;
}

.bold-font {
  font-family: Roboto Medium;
}

#tab1:active {
  color: white !important;
}

select {
  width: 100%;
}

.reg-layout {
  margin-right: 250px;
}

.main-layout-right {
  margin-right: 250px;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

// li:hover {
//   cursor: pointer;
// }
a:hover {
  cursor: pointer;
}

h3 {
  animation: top_to_bottom 3s ease;
}

.logo-menu,
h5 {
  // text-align: right;
  animation: left_to_right 3s ease;
}

@keyframes right_to_left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0;
  }
}

.card {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.card:hover {
  top: -10px;
}

@keyframes left_to_right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}

@keyframes top_to_bottom {
  from {
    margin-top: -100%;
  }

  to {
    margin-top: 0;
  }
}

label:focus {
  color: white;
}

label:active {
  color: white;
}

main {
  //max-width: 800px;
  //width: 93%;
  //margin: 30px auto;
  background: white;
  //padding: 30px;
  //box-shadow: 0 3px 5px rgba(233, 8, 8, 0.2);
}

input[name="css-tabs"] {
  display: none;
}

// a {
// 	color: white;
// }
#tabs {
  //padding: 0 0 0 50px;
  //width: calc(100% + 50px);
  //margin-left: -50px;
  background: white;
  //height: 80px;
  border-bottom: 5px solid #064e3b;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

#tabs::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -100;
  width: 115px;
  left: 0;
  margin-top: 16px;
  //height: 80px;
  background: #064e3b;
  border-bottom: 5px solid #064e3b;
}

#tabs::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  height: 80px;
  width: 25%;
  background: #064e3b;
  transition: transform 400ms;

  span {
    color: white;
  }
}

#tabs label {
  position: relative;
  z-index: 100;
  color: #064e3b;
  display: block;
  float: left;
  font-size: 11px;
  text-align: center;
  width: 25%;
  height: 100%;
  //border-right: 1px dotted white;
  cursor: pointer;
}

#tabs label::before {
  content: "";
  display: block;
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  //filter: invert(40%);
  margin: 10px auto;
}

#tab1::before {
  background-image: url(https://image.flaticon.com/icons/png/512/45/45880.png);
}

#tab2::before {
  background-image: url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_local_shipping_48px-512.png);
}

#tab3::before {
  background-image: url(https://icon-library.com/images/blend-icon/blend-icon-13.jpg);
}

#tab4::before {
  background-image: url(https://image.flaticon.com/icons/png/512/91/91841.png);
}

#radio1:checked~#tabs #tab1::before,
#radio2:checked~#tabs #tab2::before,
#radio3:checked~#tabs #tab3::before,
#radio4:checked~#tabs #tab4::before {
  filter: grayscale(100%);
}

#radio1:checked~#tabs::after {
  transform: translateX(0);
}

#radio2:checked~#tabs::after {
  transform: translateX(100%);
}

#radio3:checked~#tabs::after {
  transform: translateX(200%);
}

#radio4:checked~#tabs::after {
  transform: translateX(300%);
}

#content {
  position: relative;
  height: 500px;
}

#content::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -50px;
  border-top: 8px solid #000;
  border-right: 10px solid #000;
  border-left: 10px solid transparent;
  border-bottom: 8px solid transparent;
}

#content::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  margin-left: calc(100% + 30px);
  border-top: 8px solid #000;
  border-left: 10px solid #000;
  border-right: 10px solid transparent;
  border-bottom: 8px solid transparent;
}

#content section {
  position: absolute;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}

#radio1:checked~#content #content1,
#radio2:checked~#content #content2,
#radio3:checked~#content #content3,
#radio4:checked~#content #content4 {
  transform: translateY(0);
  opacity: 1;
  color: white;
}

// ############# pagy template styling
.pagy-nav.pagination {
  display: inline-flex;
  z-index: 0;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.pagy-nav .page {
  --tw-text-opacity: 1;
  color: rgba(136, 158, 137, var(--tw-text-opacity));
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  display: inline-flex;
  border-width: 1px;
  border-color: #899E88;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(192, 204, 218, var(--tw-border-opacity));
}

.pagy-nav .page:hover {
  background: #899E88;
  color: white;
  cursor: pointer;
}

.pagy-nav .disabled {
  color: gray;
}

.pagy-nav .active {
  background: #899E88;
  color: white;
  border-width: 1px;
  border-color: #899E88;
}

.pagy-nav .prev {
  color: gray;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-width: 1px;
}

strong {
  font-weight: bolder !important;
}

.pagy-nav .next {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-width: 1px;
}

.pagy-nav .page a,
.pagy-nav .page.active,
.pagy-nav .page.prev.disabled,
.pagy-nav .page.next.disabled,
.pagy-nav .page.gap {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

// ############# pagy template styling ENDs

@media (max-width: 850px) {
  .mob-nav {
    width: 100% !important;
  }

  .mob-bottom-space {
    margin-top: 180px;
  }
}

@media (max-width: 640px) {
  .reg-layout {
    margin-right: 0px;
  }

  .mob-bottom-space {
    margin-top: 170px;
  }

  .main-layout {
    margin-left: 0px;
  }

  .tablet\:text-center {
    text-align: center;
  }

  body {
    .main-page {
      main {
        #tabs::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 0;
          height: 124px;
          width: 25%;
          padding-bottom: 10px;
          background: #064e3b;
          transition: transform 400ms;

          span {
            color: white;
          }
        }
      }
    }
  }
}

.right-section {
  margin-left: 25%;
}

.dropdown-menu {
  display: block;
}

html,
body {
  height: 100%;
}

.timeline-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 30vh;
}

.gallery-image {
  display: inline-block;

  img {
    border-radius: 5px;
    object-fit: contain;
    width: 267px;
    height: 180px !important;
    max-width: 337px;
  }
}

.profile-section {
  img {
    height: 150px;
    width: 150px;
    object-fit: cover;
  }
}

.responsive-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 100vh;
}

.responsive-map iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.timeline {
  position: relative;
  min-height: 500px;
  width: 100%;
  padding: 0 0 60px 0;
}

.timeline__bar {
  position: absolute;
  top: 63px;
  left: 50%;
  width: 6px;
  height: 98%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: linear-gradient(#829277, #829277);
}

.timeline__bar:before,
.timeline__bar:after {
  position: absolute;
  left: 50%;
  display: block;
  width: 12px;
  height: 12px;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
}

.timeline__bar:before {
  top: -1px;
  background-color: #829277;
}

.timeline__bar:after {
  bottom: -1px;
  background-color: #829277;
}

.timeline__elem {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.timeline__elem:last-child {
  margin-bottom: 0;
}

.timeline__date {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: auto;
  color: #fff;
  background-color: #829277;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 6px solid #fff;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.timeline__date-day {
  font-size: 1.35rem;
  font-weight: 600;
}

.timeline__date-month {
  font-size: 0.85rem;
}

.timeline__event {
  position: relative;
  width: 90%;
  margin: 30px auto 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.text-ex-sm {
  font-size: 0.8rem;
}

@media (max-width: 560px) {
  .main-layout {
    margin-left: 0px;
  }

  .reg-layout {
    margin-right: 0px;
  }

  .timeline__event {
    width: 90%;
  }
}

@media (max-width: 490px) {
  .main-layout {
    margin-left: 0px;
  }

  .reg-layout {
    margin-right: 0px;
  }

  .timeline {
    width: 100%;
  }

  .timeline__event {
    width: 90%;
  }

  .footer-cls {
    width: 97.5% !important;
    margin-left: 1.25%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

@media (max-width: 560px) {
  .footer-cls {
    width: 90.5% !important;
    margin-left: 4.6% !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

.footer-cls {
  width: 22.5%;
  margin-left: 1.25%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.timeline__event:before {
  position: absolute;
  top: 50%;
  display: none;
  width: 0;
  height: 0;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.timeline__event:after {
  position: absolute;
  top: 50%;
  display: none;
  width: 16px;
  height: 16px;
  content: "";
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.timeline__event--left:before {
  right: -8px;
  border-left: 8px solid #fff;
}

.timeline__event--left:after {
  right: -7px;
  box-shadow: 2px -1px 1px 0 rgba(0, 0, 0, 0.08);
}

.timeline__event--right:before {
  left: -8px;
  border-right: 8px solid #fff;
}

.timeline__event--right:after {
  left: -7px;
  box-shadow: -2px 2px 1px 0 rgba(0, 0, 0, 0.08);
}

.timeline__event-date-time {
  padding: 20px 15px 0;
  display: flex;
  margin-bottom: 15px;
  font-size: 0.8rem;
  color: rgba(44, 44, 44, 0.7);
}

.timeline__event-date {
  margin-right: 10px;
}

.timeline__event-title {
  padding: 0 15px;
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 600;
}

.timeline__event-descr {
  padding: 0 15px 20px;
  font-size: 0.9rem;
  line-height: 1rem;
}

.timeline__event-descr p {
  margin-bottom: 10px;
}

.timeline__event-descr p:last-child {
  margin-bottom: 0;
}

.timeline__event-actions {
  padding: 20px 15px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}

.timeline__event-action {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  margin-right: 24px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.timeline__event-action:hover {
  opacity: 0.4;
}